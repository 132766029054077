import React from 'react';
import './App.css';
import DemoPage from './pages/demo/DemoPage';
import FeedPage from './pages/feed/FeedPage';
import VicoPage from './pages/telegram/VicoPage';


const App = () =>{
  return (
    <>
    <VicoPage />
    </>
  )
}
export default App;