
import './VicoPage.css';
import { useState, useEffect,useRef, useCallback } from "react"


const VicoPage = (props) => {
  const [tgData, setTgData] = useState(undefined);



  const initTg = () =>{
    if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp){
      console.log('Telegram WebApp is set');
      const tgData = window.Telegram.WebApp;
      const bac = {a:'asda', c:'asdasd', d:'asdasda'}
      setTgData({
        tg_uid:tgData.initDataUnsafe.user?.id,
        tg_name:tgData.initDataUnsafe.user?.first_name,
        tg_username:tgData.initDataUnsafe.user?.username,
        language_code:tgData.initDataUnsafe.user?.language_code,
        start_param:tgData.initDataUnsafe?.start_param,
        hash:tgData.initDataUnsafe?.hash,
      })
      // console.log(tgData);
      // console.log(tgData.initDataUnsafe);
    }
  }
  useEffect(()=>{
    initTg()
  },[]);
  return (
  <>
  <main>
    <h1 className='vico-p'>MiniAPP</h1>
    <h1 className='vico-p'>TG用户ID:{tgData?tgData.tg_uid:''}</h1>
    <h1 className='vico-p'>TG用户昵称:{tgData?tgData.tg_name:''}</h1>
    <h1 className='vico-p'>TG用户名:{tgData?tgData.tg_username:''}</h1>
    <h1 className='vico-p'>TG的语言:{tgData?tgData.language_code:''}</h1>
    <h1 className='vico-p'>开始命令参数:{tgData?tgData.start_param:''}</h1>
    <h1 className='vico-p'>后端校验哈希:{tgData?tgData.hash:''}</h1>
  </main>
  </>)
}


export default VicoPage;